import React from 'react';
// components
import Tooltip from 'rc-tooltip';
// assets
import './OptionCheckbox.css';
import QuestionMarkIcon from '../images/question-mark.svg';

import { Checkbox } from '../../common';

type Props = {
  defaultValue: Boolean,
  tooltipLabel: String,
  tooltipText: String,
  tooltipPlacement: String,
  clickHandler: () => void,
  value: boolean,
};

const OptionCheckbox = (props: Props) => {
  return (
    <div className="option-checkbox-container">
      <div className="option-checkbox-tooltip-container">
        <Tooltip
          placement={props.tooltipPlacement}
          trigger={['hover']}
          overlay={<p className="tooltip-container">{props.tooltipText}</p>}
        >
          <img
            src={QuestionMarkIcon}
            alt="Saiba mais"
            className="option-checkbox-tooltip"
          />
        </Tooltip>
        <p>{props.tooltipLabel}</p>
      </div>
      <div className="item-portioning-checkbox-item">
        <Checkbox
          label={<span></span>}
          checked={props.value}
          onChange={() => {
            props.clickHandler(!props.value);
          }}
        />
      </div>
    </div>
  );
};

export default OptionCheckbox;
