import { quantityConverter } from '../../common';
import Slider from 'rc-slider';
import PortioningValuesBox from './PortioningValuesBox';

const PortionSlider = ({ portion ,item, clickHandler }) => {

  const generateMarks = (minQuantity, maxQuantity) => {
    const marks = {};
    const startMark = minQuantity >= 1000 ? minQuantity : 1000;
    for (let i = startMark; i <= maxQuantity; i += 1000) {
      marks[i] = `${i / 1000}kg`;
    }
    return marks;
  }

  const defineMinMaxQuantity = (quantity) => {
    const minQuantity = Math.max(
      Math.ceil(quantity / 30 / 100) * 100,
      500,
    );
    const maxQuantity = Math.min(10000, quantity); 

    return [minQuantity, maxQuantity]
  }

  const [minQuantity, maxQuantity] = defineMinMaxQuantity(item.quantity)

  const incrementStep = 100;
  
  return <>
  <div className="item-portioning-edit">
    <div className="item-slider-content">
      <Slider
        min={minQuantity}
        max={maxQuantity}
        step={incrementStep}
        marks={generateMarks(
          minQuantity,
          maxQuantity,
        )}
        defaultValue={
          item.portioningSize >= minQuantity
            ? item.portioningSize
            : minQuantity
        }
        value={item.portioningSize != null ? item.portioningSize : portion}
        onChange={value => {
          clickHandler(value ? value: minQuantity)
        }}
        trackStyle={{ backgroundColor: '#eb5b1e' }}
        handleStyle={{ borderColor: '#ce3c25' }}
      />
    </div>
    <div className="item-portioning-size-block">
      <span>
        {item && quantityConverter(
          portion,
          item.variant.unitForQuantity,
        )}
      </span>
    </div>
  </div>

  {(item.portioningSize && (
    <PortioningValuesBox quantity={item.quantity} portioningSize={item.portioningSize}/> 
  )) ||
    null}
</>
}

export default PortionSlider